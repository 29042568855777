import * as React from "react";
import ArtistCard from "../../Cards/ArtistCard/ArtistCard";
import {
  Box,
  Grid,
  Container,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Autocomplete,
  Button,
} from "@mui/material";
import debounce from "@mui/material/utils/debounce";
import { Artist } from "../../Cards/ArtistCard/ArtistCardTypes";

interface ApiResponse {
  total_artists_count: number;
  total_pages: number;
  current_page: number;
  artists: Artist[];
}

interface ArtistOption {
  id: number;
  name: string;
}

const ArtistCardList: React.FC = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const isAfterMay17 = today > new Date(currentYear, 4, 17); // May is month 4 (0-indexed)

  const initialYear = isAfterMay17
    ? (currentYear + 1).toString()
    : currentYear.toString();

  const [artists, setArtists] = React.useState<Artist[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [name, setName] = React.useState<string>("");
  const [year, setYear] = React.useState<string>(initialYear);
  const [orderBy, setOrderBy] = React.useState<string>("total_streams");
  const [artistOptions, setArtistOptions] = React.useState<ArtistOption[]>([]);
  const [selectedArtists, setSelectedArtists] = React.useState<ArtistOption[]>(
    []
  );
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [page, setPage] = React.useState<number>(1);
  const observer = React.useRef<IntersectionObserver | null>(null);

  // Scroll container ref to jump to the top
  const containerRef = React.useRef<HTMLDivElement>(null);

  const fetchArtists = async () => {
    setLoading(true);
    const selectedNames = selectedArtists
      .map((artist) => artist.name)
      .join(",");
    const url = `${baseURL}/api/artist-latest-stream-count/?format=json&name=${selectedNames}&year=${year}&orderBy=${orderBy}&page=${page}`;
    const response = await fetch(url);
    const data: ApiResponse = await response.json();
    console.log("API Response:", data);

    if (page === 1) {
      setArtists(data.artists);
    } else {
      setArtists((prevArtists) => [...prevArtists, ...data.artists]);
    }

    setHasMore(data.current_page < data.total_pages);
    setLoading(false);
  };

  // Scroll to top and fetch new data when filters are changed
  React.useEffect(() => {
    setPage(1);
    setArtists([]); // Clear existing artists
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to top
    }
    fetchArtists();
  }, [selectedArtists, year, orderBy]);

  React.useEffect(() => {
    if (page > 1) {
      fetchArtists();
    }
  }, [page]);

  const lastArtistRef = React.useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const fetchArtistSuggestions = React.useMemo(
    () =>
      debounce((query: string) => {
        if (query.length === 0) {
          setArtistOptions([]);
          return;
        }
        fetch(`${baseURL}/api/artist-suggestions/?query=${query}`)
          .then((response) => response.json())
          .then((data) => setArtistOptions(data))
          .catch((error) =>
            console.error("Error fetching artist data:", error)
          );
      }, 400),
    [baseURL]
  );

  const handleArtistInputChange = (
    event: React.SyntheticEvent,
    value: string
  ) => {
    setName(value);
    fetchArtistSuggestions(value);
  };

  const generateYearOptions = () => {
    const startYear = parseInt(initialYear, 10);
    const years = [];
    for (let y = startYear; y >= 2009; y--) {
      years.push(
        <MenuItem
          key={y}
          value={y.toString()}
          sx={{ justifyContent: "center" }}
        >
          {y}
        </MenuItem>
      );
    }
    return years;
  };

  const resetFilters = () => {
    setName("");
    setSelectedArtists([]);
    setYear(initialYear);
    setOrderBy("total_streams");
    setPage(1);
    setArtists([]); // Clear artists when resetting
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to top
    }
  };

  

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
        backgroundColor: "var(--mui-palette-background-default)",
      }}
    >
      <Container maxWidth="lg" sx={{ maxWidth: "100%" }} ref={containerRef}>
        <Box
          sx={{
            position: "sticky",
            top: "57px", // Adjust based on your navbar height
            zIndex: 5,
            backgroundColor: "#f0f0f0",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            padding: "16px",
            width: "100%",
            maxWidth: "100%",
            maxHeight: "180px",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="artist-search-input"
                options={artistOptions}
                getOptionLabel={(option: ArtistOption) => option.name}
                filterSelectedOptions
                value={selectedArtists}
                onChange={(event, newValues) => setSelectedArtists(newValues)}
                inputValue={name}
                onInputChange={handleArtistInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Søk etter artist"
                    placeholder="Søk etter flere artister"
                  />
                )}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="year-label">År</InputLabel>
                <Select
                  labelId="year-label"
                  label="År"
                  value={year}
                  onChange={(e) => setYear(e.target.value as string)}
                  sx={{
                    backgroundColor: "white",
                    "& .MuiSelect-select": {
                      textAlign: "center",
                    },
                  }}
                >
                  <MenuItem value="-1" sx={{ justifyContent: "center" }}>
                    Alle år
                  </MenuItem>
                  {generateYearOptions()}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="orderBy-label">Sortering</InputLabel>
                <Select
                  labelId="orderBy-label"
                  label="Sorter etter"
                  value={orderBy}
                  onChange={(e) => setOrderBy(e.target.value as string)}
                  sx={{
                    backgroundColor: "white",
                    "& .MuiSelect-select": {
                      textAlign: "center",
                    },
                  }}
                >
                  <MenuItem
                    value="total_streams"
                    sx={{ justifyContent: "center" }}
                  >
                    Streams
                  </MenuItem>
                  <MenuItem
                    value="total_weekly_streams"
                    sx={{ justifyContent: "center" }}
                  >
                    Trend
                  </MenuItem>
                  <MenuItem
                    value="song_count"
                    sx={{ justifyContent: "center" }}
                  >
                    Antall
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {/* Padding to ensure the first card is fully visible initially */}
        <Box
          sx={{
            position: "sticky",
            zIndex: 1
          }}
        >
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            maxWidth="md"
            sx={{
              position: "sticky",
              margin: "0 auto", // Ensure grid is centered within its container
            }}
          >
            {artists.map((artist, index) => {
              let gridSize = { xs: 12, sm: 12, md: 4, lg: 7 };
              const isLoadMoreTrigger = index === artists.length - 1;

              return (
                <Grid item {...gridSize} key={artist.spotify_id}>
                  <Box
                    ref={isLoadMoreTrigger ? lastArtistRef : null}
                    sx={{ width: "100%", margin: "0 auto" }}
                  >
                    <ArtistCard artist={artist} />
                  </Box>
                </Grid>
              );
            })}
          </Grid>

          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ArtistCardList;
