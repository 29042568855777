import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Grid, Button, Typography, Container, Card, CardMedia, CardContent, Skeleton, FormControl, InputLabel, Select } from '@mui/material';
import { red } from '@mui/material/colors';

// Define an interface for Spotify track details
interface SpotifyTrackDetails {
  title: string;
  artists: string;
  coverArt: string;
}

const SongSubmissionForm: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2008 }, (_, i) => currentYear + 2 - i);

  const [year, setYear] = useState("");
  const [spotifyUrl, setSpotifyUrl] = useState('');
  const [group, setGroup] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [spotifyPreview, setSpotifyPreview] = useState<SpotifyTrackDetails | null>(null);  // Use SpotifyTrackDetails or null
  const [loading, setLoading] = useState(false);

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  // Function to extract track ID from Spotify URL
  const extractSpotifyTrackId = (url: string) => {
    const match = url.match(/^https:\/\/open\.spotify\.com\/track\/([a-zA-Z0-9]+)(?:\?si=.*)?$/);
    return match ? match[1] : null;
  };

  // Fetch Spotify track details by sending the Spotify URL to your backend
  useEffect(() => {
    const fetchSpotifyTrack = async (trackId: string) => {
      if (!spotifyUrl) return;

      try {
        setLoading(true);
        const response = await axios.get(`${baseURL}/api/get-spotify-track/`, {
          params: { spotify_url: spotifyUrl }
        });

        if (response.status === 200) {
          const { name, artists, album } = response.data;
          setSpotifyPreview({
            title: name,
            artists: artists.map((artist: any) => artist.name).join(', '),
            coverArt: album.images[0].url
          });
        } else {
          setSpotifyPreview(null);
        }
      } catch (error) {
        console.error("Error fetching Spotify track details:", error);
        setSpotifyPreview(null);
      } finally {
        setLoading(false);
      }
    };

    const trackId = extractSpotifyTrackId(spotifyUrl);
    if (trackId) {
      fetchSpotifyTrack(trackId);
    } else {
      setSpotifyPreview(null);
    }
  }, [spotifyUrl]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    const spotifyUrlRegex = /^https:\/\/open\.spotify\.com\/track\/[0-9a-zA-Z]{22}(?:\?si=.*)?$/;
    const spotifyAlbumUrlRegex = /^https:\/\/open\.spotify\.com\/album\/[0-9a-zA-Z]{22}(?:\?si=.*)?$/;

    if (spotifyAlbumUrlRegex.test(spotifyUrl)) {
      setErrorMessage('Dette er en link til et album, vi trenger linken til sangen.');
      return;
    }

    if (!spotifyUrlRegex.test(spotifyUrl)) {
      setErrorMessage('Vennligst skriv inn en gyldig Spotify-link.');
      return;
    }

    if (isNaN(Number(year))) {
      setErrorMessage('Vennligst velg et år.');
      return;
    }

    if (group.length > 50) {
      setErrorMessage('Gruppenavn kan maks inneholde 50 tegn.');
      return;
    }

    const groupRegex = /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?æøå]*$/;
    if (!groupRegex.test(group)) {
      setErrorMessage('Gruppenavn inneholder ugyldige tegn.');
      return;
    }

    window.grecaptcha.ready(async () => {
      const recaptchaToken = await window.grecaptcha.execute('6Lf_keAmAAAAABFY-6A7ZoffbWiaTQjCrH7hmgXw', { action: 'submit' });

      try {
        const response = await fetch(`${baseURL}/api/submit-song/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            spotify_url: spotifyUrl,
            year: Number(year),
            group: group,
            email: email,
            recaptchaToken
          })
        });

        if (response.ok) {
          setSuccessMessage('Sangen ble sendt inn!');
          setYear('');
          setSpotifyUrl('');
          setGroup('');
          setEmail('');
          setSpotifyPreview(null); // Clear Spotify preview on success
        } else {
          const data = await response.json();
          setErrorMessage(data.error);
        }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('En feil oppstod. Vennligst prøv igjen senere.');
      }
    });
  };

  return (
    <Container maxWidth="md" sx={{ mt: 2 }}>
      <Grid container spacing={3} direction="column" justifyContent="center">
        <Grid item xs={12}>
          {errorMessage && <Typography color="error" gutterBottom sx={{ textAlign: 'center' }}>{errorMessage}</Typography>}
          {successMessage && <Typography color="success" gutterBottom sx={{ textAlign: 'center' }}>{successMessage}</Typography>}

          {/* Skeleton as default before loading */}
          {(!spotifyPreview && !loading) && (
            <Card sx={{
              display: 'flex',
              mb: 2,
              borderRadius: '8px',  // Same rounded corners as Paper
              overflow: 'hidden',    // Ensures the skeleton's rounded corners align perfectly with the container
            }}>
              <Skeleton variant="rectangular" sx={{
                width: 80,  // Fixed width
                height: 80,  // Fixed height for 1:1 aspect ratio
                objectFit: 'cover',
                flexShrink: 0,
                borderRadius: '0px', // Remove inner border radius to align with outer card
              }} />
              <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1, padding: '8px' }}>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="60%" />
              </CardContent>
            </Card>
          )}

          {/* Skeleton while loading */}
          {loading && (
            <Card sx={{
              display: 'flex',
              mb: 2,
              borderRadius: '8px',  // Same rounded corners as Paper
              overflow: 'hidden',    // Ensures the skeleton's rounded corners align perfectly with the container
            }}>
              <Skeleton variant="rectangular" sx={{
                width: 80,  // Fixed width
                height: 80,  // Fixed height for 1:1 aspect ratio
                objectFit: 'cover',
                flexShrink: 0,
                borderRadius: '0px', // Remove inner border radius to align with outer card
              }} />
              <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1, padding: '8px' }}>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="60%" />
              </CardContent>
            </Card>
          )}

          {/* Show actual preview once data is fetched */}
          {spotifyPreview && (
            <Card sx={{ display: 'flex', mb: 2, borderRadius: '8px', overflow: 'hidden', maxWidth: '100%' }}>
              <CardMedia
                component="img"
                sx={{
                  width: 80,  // Fixed width for 1:1 aspect ratio
                  height: 80,
                  objectFit: 'cover',
                  flexShrink: 0,
                  borderRadius: '0px',  // Align with outer container
                  margin: 0,            // Remove any potential margin
                  padding: 0,           // Remove any potential padding
                  border: 'none',       // Ensure no border exists
                  display: 'block',     // Ensure it doesn't behave as an inline element
                }}
                image={spotifyPreview.coverArt}
                alt={spotifyPreview.title}
              />
              <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flexGrow: 1,
                padding: '6px',
                overflow: 'hidden'
              }}>
                <Typography
                  component="div"
                  variant="h6"
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    maxWidth: '100%'
                  }}
                >
                  {spotifyPreview.title.length > 20 ? spotifyPreview.title.substring(0, 20) + '...' : spotifyPreview.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={{
                    fontSize: '0.85rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    maxWidth: '100%',
                    flexShrink: 1
                  }}
                >
                  {spotifyPreview.artists.length > 30 ? spotifyPreview.artists.substring(0, 30) + '...' : spotifyPreview.artists}
                </Typography>
              </CardContent>
            </Card>
          )}

          <form onSubmit={handleSubmit}>
            <TextField
              label="Spotify Link"
              variant="outlined"
              value={spotifyUrl}
              onChange={(e) => setSpotifyUrl(e.target.value)}
              required
              fullWidth
              margin="normal"
              sx={{
                mb: 2,
                bgcolor: 'white',
                borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 0, 0, 0.5)',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'rgba(255, 0, 0, 0.8)',
                },
              }}
            />

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" sx={{
                  bgcolor: 'white',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 0, 0, 0.5)',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'rgba(255, 0, 0, 0.8)',
                  },
                }}>
                  <InputLabel>År</InputLabel>
                  <Select
                    native
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    label="År"
                    inputProps={{
                      name: 'year',
                      id: 'outlined-year-native-simple',
                    }}
                  >
                    <option aria-label="None" value="" />
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Gruppe"
                  variant="outlined"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                  required
                  fullWidth
                  sx={{
                    bgcolor: 'white',
                    borderRadius: '8px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 0, 0, 0.5)',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'rgba(255, 0, 0, 0.8)',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Email (Valgfritt)"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  sx={{
                    bgcolor: 'white',
                    borderRadius: '8px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 0, 0, 0.5)',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'rgba(255, 0, 0, 0.8)',
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                py: 1.5,
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: red[500],
                color: 'white',
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(255, 105, 135, 0.3)',
                '&:hover': {
                  backgroundColor: red[700],
                }
              }}
            >
              Send inn
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SongSubmissionForm;
