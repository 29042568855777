import React, { FC, useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import CardList from "../Lists/SongCardList/CardList";
import ScrollTopButton from "../ScrollTopButton/ScrollTopButton";
import SearchBar from "../Search/SearchBar";
import ArtistSearchBar from "../Search/ArtistSearchBar";
import YearPicker from "../YearPicker/YearPicker";
import FloatingSearchButton from "../Search/FloatingSearchButton";
import FloatingResetButton from "../Search/FloatingResetButton";
import StreamFilterButton from "../StreamFilterButton/StreamFilterButton";
import theme from "../../theme";
import { Artist } from "../../types";
import { useSearchParams } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import AddButton from '../AddButton/AddButton';

const MainPage: FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const isParamPresent = (paramName: string) => searchParams.has(paramName);

  // Keep 2025 as the default year unless "all years" (-1) is chosen
  const initialYear = isParamPresent("year") 
    ? parseInt(searchParams.get("year") || "", 10) 
    : 2025;  // Default to 2025
  const initialSearchTerm = searchParams.get("search") || "";
  const initialArtistsParam = searchParams.get("artists") || "";
  const initialArtists = initialArtistsParam ? initialArtistsParam.split(",") : [];
  const initialStreamFilter = searchParams.get("streamFilter") === "true";

  const [selectedYear, setSelectedYear] = useState<number | null>(initialYear);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [artistSearchTerm, setArtistSearchTerm] = useState<string[]>(initialArtists);
  const [streamFilter, setStreamFilter] = useState(initialStreamFilter);
  const [showFilters, setShowFilters] = useState(false);
  const [totalSongsCount, setTotalSongsCount] = useState(0);

  const handleYearChange = (year: number | null) => {
    setSelectedYear(year);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("year", year === null ? '-1' : year.toString());  // Set to -1 if no specific year is selected
    if (searchTerm) newSearchParams.set("search", searchTerm);
    if (artistSearchTerm.length) newSearchParams.set("artists", artistSearchTerm.join(","));
    newSearchParams.set("streamFilter", streamFilter.toString());

    setSearchParams(newSearchParams, { replace: true });
  };

  const handleStreamFilterChange = (isActive: boolean) => {
    setStreamFilter(isActive);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("streamFilter", isActive.toString());
    if (selectedYear !== null) newSearchParams.set("year", selectedYear.toString());
    if (searchTerm) newSearchParams.set("search", searchTerm);
    if (artistSearchTerm.length) newSearchParams.set("artists", artistSearchTerm.join(","));

    setSearchParams(newSearchParams, { replace: true });
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("search", term);
    if (selectedYear !== null) newSearchParams.set("year", selectedYear.toString());
    if (artistSearchTerm.length) newSearchParams.set("artists", artistSearchTerm.join(","));
    newSearchParams.set("streamFilter", streamFilter.toString());

    setSearchParams(newSearchParams, { replace: true });
  };

  const handleArtistsChange = (artists: Artist[]) => {
    const artistNames = artists.map(artist => artist.name);
    setArtistSearchTerm(artistNames);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("artists", artistNames.join(","));
    if (selectedYear !== null) newSearchParams.set("year", selectedYear.toString());
    if (searchTerm) newSearchParams.set("search", searchTerm);
    newSearchParams.set("streamFilter", streamFilter.toString());

    setSearchParams(newSearchParams, { replace: true });
  };

  const resetToDefault = (event: React.MouseEvent) => {
    event.preventDefault();

    const defaultYear = 2025;  // Set back to 2025 when reset
    setSelectedYear(defaultYear);
    setSearchTerm("");
    setArtistSearchTerm([]);
    setStreamFilter(false);

    const newSearchParams = new URLSearchParams();
    newSearchParams.set("year", defaultYear.toString());
    newSearchParams.set("streamFilter", "false");
    setSearchParams(newSearchParams, { replace: true });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    const yearParam = searchParams.get("year");
    const searchTermParam = searchParams.get("search");
    const artistsParam = searchParams.get("artists");
    const streamFilterParam = searchParams.get("streamFilter");

    setSelectedYear(yearParam ? parseInt(yearParam, 10) : 2025);  // Default to 2025
    setSearchTerm(searchTermParam || "");
    setArtistSearchTerm(artistsParam ? artistsParam.split(",") : []);
    setStreamFilter(streamFilterParam === "true");
  }, [searchParams]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showResetButton = showFilters || searchTerm || artistSearchTerm.length > 0;
  const showSearchFields = isMobile ? showFilters : true;

  const fixedContainerHeight = isMobile ? (showFilters ? '250px' : '90px') : '100px';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [fixedContainerHeight]);

  const handleTotalSongsCountChange = (count: number) => {
    setTotalSongsCount(count);
  };

  useEffect(() => {
    console.log("Total Songs Count: ", totalSongsCount);
  }, [totalSongsCount]);

  return (
    <Box 
      sx={{
        position: "relative",
        minHeight: "100vh",
        backgroundColor: "var(--mui-palette-background-default)",
      }}
    >
      <Container maxWidth="lg">
        {isMobile && <AddButton />}
        {isMobile && <ScrollTopButton />}
        <Container 
          sx={{
            position: "fixed",
            zIndex: 10,
            backgroundColor: "#f0f0f0",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            padding: "16px",
            overflow: "hidden",
            transition: "max-height 0.5s ease-in-out",
            marginBottom: "16px",
            maxWidth: isMobile ? '92%' : '100%',
            maxHeight: showFilters ? '500px' : '83px',
          }}
        >
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <YearPicker
                onYearChange={handleYearChange}
                selectedYear={selectedYear}
                currentYear={2025}  // Ensure the current year is 2025
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <StreamFilterButton
                onChange={() => handleStreamFilterChange(!streamFilter)}
                active={streamFilter}
              />
            </Grid>
            {showSearchFields && (
              <>
                <Grid item xs={12} md={6} lg={4}>
                  <SearchBar
                    onSearchChange={handleSearchChange}
                    searchTerm={searchTerm}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ArtistSearchBar
                    onArtistSearchChange={handleArtistsChange}
                    artistSearchTerm={artistSearchTerm}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Container>

        {isMobile && (
          <>
            {showResetButton && (
              <FloatingResetButton onClick={resetToDefault} />
            )}
            <FloatingSearchButton onClick={toggleFilters} showFilters={showFilters} />
          </>
        )}
        <Box sx={{ paddingTop: fixedContainerHeight }}>
          <CardList
            listId="mainList"
            searchTerm={searchTerm}
            artistSearchTerm={artistSearchTerm.join(",")}
            displayDetails={["songDetails", "streamCount"]}
            selectedYear={selectedYear === null ? -1 : selectedYear}  // Pass -1 when selectedYear is null
            orderByStreamGain={streamFilter}
            onTotalSongsCountChange={handleTotalSongsCountChange}
          />
        </Box>
        {totalSongsCount > 0 && (
          <Box 
            sx={{
              position: "fixed",
              bottom: "16px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              backdropFilter: "blur(10px)",
              borderRadius: "20px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              padding: "8px 16px",
              zIndex: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                cursor: "pointer",
              }
            }}
          >
            <Typography variant="subtitle1" color="textSecondary" sx={{ ml: 1 }}>
              Sanger: {totalSongsCount}
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default MainPage;
