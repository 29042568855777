import React, { useCallback, useEffect, useState } from 'react';
import {
  Card, CardContent, CardMedia, CardActions, Pagination,
  Typography, Container, Grid, Button, Box, Paper,
  Accordion, AccordionSummary, AccordionDetails, useTheme, useMediaQuery,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton
} from '@mui/material';
import { green } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAuth } from '../../auth/auth';
import SearchBar from "../Search/SearchBar";
import ArtistSearchBar from "../Search/ArtistSearchBar";
import YearPicker from "../YearPicker/YearPicker";
import FloatingSearchButton from "../Search/FloatingSearchButton";
import FloatingResetButton from "../Search/FloatingResetButton";
import StreamFilterButton from "../StreamFilterButton/StreamFilterButton";
import { Artist } from "../../types";
import { parseISO } from 'date-fns';

// Interfaces
interface ArtistData {
  spotify_id: string;
  name: string;
}

interface SongSubmission {
  id: number;
  isrc: string;
  spotify_id: string;
  title: string;
  artist_data: ArtistData[];
  year: number;
  album_type: string;
  release_date: string;
  release_date_precision: string;
  cover_art_url: string;
  group: string;
  email?: string; // Add email field here, marked as optional
}


interface FormData {
  title: string;
  year: number;
  group: string;
}

const ITEMS_PER_PAGE = 10;

const ReviewSubmissions: React.FC = () => {
  const { apiRequest } = useAuth();

  const [totalCount, setTotalCount] = useState(0);
  const [submissions, setSubmissions] = useState<SongSubmission[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [artistSearchTerm, setArtistSearchTerm] = useState<string[]>([]);
  const [streamFilter, setStreamFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedSubmission, setSelectedSubmission] = useState<SongSubmission | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false); // Add this line
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const { register, handleSubmit, reset } = useForm<FormData>();

  useEffect(() => {
    fetchSubmissions();
  }, [selectedYear, searchTerm, artistSearchTerm, streamFilter, currentPage]);

  const getToken = () => {
    return localStorage.getItem("accessToken") || ''; 
  };

  const handleTokenRefresh = async (): Promise<string | null> => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      console.error("No refresh token available.");
      return null;
    }

    try {
      const response = await fetch(`${baseURL}/api/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh: refreshToken })
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access);
        return data.access;
      } else {
        console.error("Failed to refresh token.");
        return null;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  const fetchSubmissions = async () => {
    console.log('Fetching submissions...');
    setIsLoading(true);
  
    const queryParameters = new URLSearchParams();
    if (selectedYear) queryParameters.append('year', selectedYear.toString());
    if (searchTerm) queryParameters.append('search', searchTerm);
    if (artistSearchTerm.length) queryParameters.append('artists', artistSearchTerm.join(','));
    queryParameters.append('streamFilter', streamFilter.toString());
    queryParameters.append('page', currentPage.toString());
  
    try {
      const data = await apiRequest(`/api/pending-submissions/?${queryParameters.toString()}`, 'GET');
      console.log('Data received from fetchSubmissions:', data);
  
      if (data) {
        setSubmissions(data.results);
        console.log('Updated submissions state:', data.results);
        setTotalCount(data.count);
        setTotalPages(Math.ceil(data.count / ITEMS_PER_PAGE));
      }
    } catch (error) {
      console.error('An error occurred while fetching submissions:', error);
    }
  
    setIsLoading(false);
  };

  function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: ReturnType<typeof setTimeout> | null;
  
    return (...args: any[]) => {
      if (timeout) clearTimeout(timeout);
  
      timeout = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }
  
  // Debounced fetchSubmissions function
  const debouncedFetchSubmissions = useCallback(
    debounce(() => {
      fetchSubmissions();
    }, 5000), // Set the debounce delay to 5 seconds
    []
  );


  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleYearChange = (year: number | null) => {
    setSelectedYear(year);
  };

  const handleStreamFilterChange = (isActive: boolean) => {
    setStreamFilter(isActive);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  const handleArtistsChange = (artists: Artist[]) => {
    const artistNames = artists.map(artist => artist.name);
    setArtistSearchTerm(artistNames);
  };

  const resetToDefault = (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedYear(null);
    setSearchTerm("");
    setArtistSearchTerm([]);
    setStreamFilter(false);
    setCurrentPage(1);
  };




  const acceptSubmission = async (id: number) => {
    console.log(`Attempting to accept submission with ID: ${id}`);
    const originalSubmission = submissions.find((sub: SongSubmission) => sub.id === id);
  
    // Optimistically remove the submission from the UI immediately
    setSubmissions((prev: SongSubmission[]) => {
      const updated = prev.filter((sub: SongSubmission) => sub.id !== id);
      console.log('Updated submissions after optimistic removal in acceptSubmission:', updated);
      return updated;
    });
  
    const makeRequest = async (token: string) => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };
  
      try {
        const response = await fetch(`${baseURL}/api/review/${id}/`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ status: 'approved', reviewer_comments: 'Approved' }),
        });
  
        console.log('Response from acceptSubmission POST request:', response.status);
        if (!response.ok) {
          throw response;
        }
  
        return null; // Success, no error to return
      } catch (error) {
        console.error('Error in acceptSubmission request:', error);
        return error as Response;
      }
    };
  
    const token = getToken();
    let errorResponse = await makeRequest(token);
  
    if (errorResponse && errorResponse.status === 401) {
      console.log('Token expired, refreshing...');
      const newToken = await handleTokenRefresh();
      if (newToken) {
        errorResponse = await makeRequest(newToken);
      }
    }
  
    if (errorResponse) {
      console.error('Error accepting submission, re-adding to UI:', errorResponse);
      setSubmissions((prev: SongSubmission[]) => [...prev, originalSubmission!]);
    } else {
      console.log('Submission accepted successfully, waiting before fetching updated submissions.');
      debouncedFetchSubmissions(); // Use the custom debounced fetch
    }
  };
  
  const rejectSubmission = async (id: number) => {
    console.log(`Attempting to reject submission with ID: ${id}`);
    const originalSubmission = submissions.find((sub: SongSubmission) => sub.id === id);
  
    // Optimistically remove the submission from the UI immediately
    setSubmissions((prev: SongSubmission[]) => {
      const updated = prev.filter((sub: SongSubmission) => sub.id !== id);
      console.log('Updated submissions after optimistic removal in rejectSubmission:', updated);
      return updated;
    });
  
    const makeRequest = async (token: string) => {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };
  
      try {
        const response = await fetch(`${baseURL}/api/review/${id}/`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ status: 'rejected', reviewer_comments: 'Rejected' }),
        });
  
        console.log('Response from rejectSubmission POST request:', response.status);
        if (!response.ok) {
          throw response;
        }
  
        return null; // Success, so no error to return
      } catch (error) {
        console.error('Error in rejectSubmission request:', error);
        return error as Response;
      }
    };
  
    const token = getToken();
    let errorResponse = await makeRequest(token);
  
    if (errorResponse && errorResponse.status === 401) {
      console.log('Token expired, refreshing...');
      const newToken = await handleTokenRefresh();
      if (newToken) {
        errorResponse = await makeRequest(newToken);
      }
    }
  
    if (errorResponse) {
      console.error('Error rejecting submission, re-adding to UI:', errorResponse);
      setSubmissions((prev: SongSubmission[]) => [...prev, originalSubmission!]);
    } else {
      console.log('Submission rejected successfully, waiting before fetching updated submissions.');
      debouncedFetchSubmissions(); // Use the custom debounced fetch
    }
  };
  
  
  
  
  

  



  const openEditDialog = (submission: SongSubmission) => {
    setSelectedSubmission(submission);
    setIsEditDialogOpen(true);
    reset({
      title: submission.title,
      year: submission.year,
      group: submission.group
    });
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedSubmission(null);
  };

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    if (selectedSubmission) {
      const makeRequest = async (token: string) => {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        try {
          const response = await fetch(`${baseURL}/api/review/${selectedSubmission.id}/`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(data)
          });

          if (!response.ok) {
            throw response;
          }
          fetchSubmissions();
          closeEditDialog();
          return null;
        } catch (error) {
          return error as Response;
        }
      };

      const token = getToken();
      let errorResponse = await makeRequest(token);

      if (errorResponse && errorResponse.status === 401) {
        const newToken = await handleTokenRefresh();
        if (newToken) {
          errorResponse = await makeRequest(newToken);
        }
      }

      if (errorResponse) {
        console.error('Error updating submission:', errorResponse);
      }
    }
  };

  const handleImageClick = (submission: SongSubmission) => {
    setSelectedSubmission(submission);
  };

  const isYearCorrect = (releaseDate: string, year: number) => {
    const releaseDateObj = parseISO(releaseDate);
    const cutoffDate = new Date(releaseDateObj.getFullYear(), 4, 17); // May 17 of the release year
    
    if (releaseDateObj <= cutoffDate) {
      return releaseDateObj.getFullYear() === year;
    } else {
      return releaseDateObj.getFullYear() + 1 === year;
    }
  };

  return (
    <Container>
      <Dialog open={!!selectedSubmission} onClose={() => setSelectedSubmission(null)} aria-labelledby="submission-details-dialog">
        <DialogTitle id="submission-details-dialog">Submission Details</DialogTitle>
        <DialogContent>
          {selectedSubmission && (
            <>
              <Typography variant="h6" gutterBottom>{selectedSubmission.title}</Typography>
              <Typography variant="body1" gutterBottom>ISRC: {selectedSubmission.isrc}</Typography>
              <Typography variant="body1" gutterBottom>
                <a href={`https://open.spotify.com/track/${selectedSubmission.spotify_id}`} target="_blank" rel="noopener noreferrer">
                  Listen on Spotify
                </a>
              </Typography>
              <Typography variant="body1" gutterBottom>Year: {selectedSubmission.year}</Typography>
              <Typography variant="body1" gutterBottom>Group: {selectedSubmission.group}</Typography>
              <Typography variant="body1" gutterBottom>Album Type: {selectedSubmission.album_type}</Typography>
              <Typography variant="body1" gutterBottom>Release Date: {selectedSubmission.release_date}</Typography>
              <Typography variant="body1" gutterBottom>Release Date Precision: {selectedSubmission.release_date_precision}</Typography>
              <Typography variant="body1" gutterBottom>Email: {selectedSubmission.email || 'No email provided'}</Typography> {/* Display email */}
              <img src={selectedSubmission.cover_art_url} alt="Cover Art" style={{ maxWidth: '100%', marginTop: '10px' }} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedSubmission(null)}>Close</Button>
        </DialogActions>
      </Dialog>


      {/* Dialog for Editing Submission */}
      <Dialog open={isEditDialogOpen} onClose={closeEditDialog}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Edit Submission</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Title"
              fullWidth
              variant="outlined"
              {...register('title')}
            />
            <TextField
              margin="dense"
              label="Year"
              fullWidth
              variant="outlined"
              type="number"
              {...register('year')}
            />
            <TextField
              margin="dense"
              label="Group"
              fullWidth
              variant="outlined"
              {...register('group')}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditDialog}>Close</Button>
            <Button type="submit" color="primary">Save</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Filter Accordion */}
      <Accordion defaultExpanded={!isMobile}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
            <YearPicker
              onYearChange={handleYearChange}
              selectedYear={selectedYear}
              currentYear={new Date().getFullYear()}
            />
            <SearchBar
              onSearchChange={handleSearchChange}
              searchTerm={searchTerm}
            />
            <ArtistSearchBar
              onArtistSearchChange={handleArtistsChange}
              artistSearchTerm={artistSearchTerm}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      {isMobile && (
        <>
          <FloatingResetButton onClick={resetToDefault} />
          <FloatingSearchButton onClick={() => setShowFilters(!showFilters)} showFilters={showFilters} />
        </>
      )}

      {/* Pagination Component */}
      <Box display="flex" justifyContent="center" my={2}>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary" disabled={isLoading} />
      </Box>

      {/* Submissions Grid or Loading Indicator */}
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        totalCount > 0 && (
          <Grid container spacing={4}>
            {submissions.map((submission) => (
              <Grid item key={submission.id} xs={12} sm={6} md={4}>
                <Card
                  elevation={3}
                  sx={{
                    maxWidth: 345,
                    borderRadius: 2,
                    position: 'relative',
                    '&:hover': { boxShadow: '0 10px 20px rgba(0,0,0,0.3)' }
                  }}
                >
                  {/* Year Validity Indicator */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 8,
                      left: 8,
                      backgroundColor: 'black',
                      color: 'white',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      zIndex: 1
                    }}
                  >
                    {isYearCorrect(submission.release_date, submission.year) ? '📅 ✅' : '📅 ❌'}
                  </Box>

                  {/* Year Indicator */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: 'black',
                      color: 'white',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      zIndex: 1
                    }}
                  >
                    {submission.year}
                  </Box>

                  <CardMedia
                    component="img"
                    height="140"
                    image={submission.cover_art_url}
                    alt={submission.title}
                    sx={{ objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() => handleImageClick(submission)}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {submission.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {submission.artist_data.map((artist, index) => (
                        <span key={artist.spotify_id}>
                          {artist.name}{index < submission.artist_data.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                      startIcon={<CheckCircleIcon />}
                      variant="contained"
                      sx={{ bgcolor: green[500], color: '#fff' }}
                      onClick={() => acceptSubmission(submission.id)}
                    >
                      Accept
                    </Button>
                    <IconButton onClick={() => openEditDialog(submission)} color="primary">
                      <EditIcon />
                    </IconButton>
                    <Button
                      startIcon={<CancelIcon />}
                      variant="contained"
                      color="error"
                      onClick={() => rejectSubmission(submission.id)}
                    >
                      Reject
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

            ))}
          </Grid>
        )
      )}
    </Container>
  );
};

export default ReviewSubmissions;
