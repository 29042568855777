import * as React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { red } from '@mui/material/colors';


const AddButton: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/send-inn"); // Redirect to /send-inn
  };

  return (
    <Fab
      color="primary"
      size="medium"
      onClick={handleClick}
      sx={{
        position: 'fixed',
        bottom: '5%', // Adjust this value to position the button correctly
        zIndex: 1050,
        backgroundColor: red[500],
        boxShadow: '0px 4px 12px rgba(255, 105, 135, 0.3)',
                '&:hover': {
                  backgroundColor: red[700],
                },
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        padding: '12px',
        right: { xs: '2%', md: '7%', custom: '10%', lg: '30%' },
      }}
    >
      <AddIcon style={{ fontSize: '32px' }} />
    </Fab>
  );
};

export default AddButton;
