import * as React from "react";
import { Box, TextField, IconButton, InputAdornment } from "@mui/material";
import debounce from "@mui/material/utils/debounce";
import ClearIcon from '@mui/icons-material/Clear'; // Import the clear icon

interface SearchBarProps {
  onSearchChange: (term: string) => void;
  searchTerm: string;
  reset?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearchChange,
  searchTerm: propSearchTerm,
  reset,
}) => {
  const [searchTerm, setSearchTerm] = React.useState(propSearchTerm);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    debouncedSearchChange(newValue);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    onSearchChange(""); // Clear the search externally as well
  };

  const debouncedSearchChange = React.useMemo(
    () =>
      debounce((query: string) => {
        onSearchChange(query);
      }, 300),
    [onSearchChange]
  );

  React.useEffect(() => {
    if (reset) {
      setSearchTerm("");
    }
  }, [reset]);

  React.useEffect(() => {
    setSearchTerm(propSearchTerm);
  }, [propSearchTerm]);

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <TextField
        id="search-input"
        value={searchTerm}
        onChange={handleSearchChange}
        label="Søk etter tittel"
        variant="outlined"
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255, 0, 0, 0.5)',
            },
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: 'rgba(255, 0, 0, 0.8)',
          },
        }}
        InputProps={{
          endAdornment: (
            searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClearSearch}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
