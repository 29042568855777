// NavBarContents.tsx
import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { red, grey } from "@mui/material/colors";
import { Stack, Link, Button } from "@mui/material";

interface NavBarContentsProps {
  onLogout: () => void;
  isAuthenticated: boolean;
}

const NavBarContents: React.FC<NavBarContentsProps> = ({ onLogout, isAuthenticated }) => {
  // Updated styles
  const customRed = "#EF5350";

  // Updated active button style
  const activeButtonStyle = {
    textDecoration: 'none',
    padding: '10px 15px',
    border: `1px solid ${customRed}`,
    borderRadius: '4px',
    backgroundColor: customRed,
    color: 'white',
    '&:hover': {
      backgroundColor: red[700], // Darker shade when hovered
    },
  };

  // Updated non-active button style
  const nonActiveButtonStyle = {
    textDecoration: 'none',
    padding: '10px 15px',
    border: `1px solid transparent`,
    borderRadius: '4px',
    backgroundColor: 'transparent',
    color: customRed,
    '&:hover': {
      backgroundColor: red[50], // Lighter shade when hovered
      borderColor: customRed,
    },
  };

  const getNavLinkStyle = (path: string) => 
    window.location.pathname === path ? activeButtonStyle : nonActiveButtonStyle;

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <NavLink to="/" style={getNavLinkStyle("/")}>Topplisten</NavLink>
      <NavLink to="/artister" style={getNavLinkStyle("/artister")}>Artister</NavLink>
      <NavLink to="/om" style={getNavLinkStyle("/om")}>Om</NavLink>
      <NavLink to="/send-inn" style={getNavLinkStyle("/send-inn")}>Send inn</NavLink>
      
      {/* Conditional Logout Button and Review NavLink */}
      {isAuthenticated && (
        <React.Fragment>
          <NavLink to="/innsendinger" style={getNavLinkStyle("/innsendinger")}>Review</NavLink>
          <Button onClick={onLogout} style={nonActiveButtonStyle}>Logout</Button>
        </React.Fragment>
      )}

      {/* Social Media Icons */}
      <Link href="https://www.tiktok.com/@russetoppen" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTiktok} size="2x" style={{ color: red[500] }} />
      </Link>
      <Link href="https://www.instagram.com/russetoppen" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: red[500] }} />
      </Link>
    </Stack>
  );
};

export default NavBarContents;