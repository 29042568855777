import * as React from 'react';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { red } from '@mui/material/colors';


const ScrollTopButton: React.FC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Zoom in={trigger}>
<Fab 
  color="primary" 
  size="medium" 
  onClick={handleClick} 
  sx={{
    position: 'fixed',
    bottom: '15%',
    zIndex: 1050,
    backgroundColor: red[500],
    boxShadow: '0px 4px 12px rgba(255, 105, 135, 0.3)',
            '&:hover': {
              backgroundColor: red[700],
            },
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    padding: '12px',
    right: { xs: '2%', md: '7%', custom: '10%', lg: '30%' },
  }}
>
  <KeyboardArrowUpIcon style={{ fontSize: '32px' }} />
</Fab>

    </Zoom>
  );
};

export default ScrollTopButton;
