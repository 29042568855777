import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import debounce from "@mui/material/utils/debounce";

interface Artist {
  id: number;
  name: string;
}

interface ArtistSearchBarProps {
  onArtistSearchChange: (artists: Artist[]) => void;
  artistSearchTerm: string[];
  reset?: boolean;
}

const ArtistSearchBar: React.FC<ArtistSearchBarProps> = ({
  onArtistSearchChange,
  artistSearchTerm,
  reset,
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const [selectedArtists, setSelectedArtists] = React.useState<Artist[]>(
    artistSearchTerm.map((name) => ({ id: 0, name }))
  );
  const [options, setOptions] = React.useState<readonly Artist[]>([]);

  const fetchArtists = React.useMemo(
    () =>
      debounce((query: string) => {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseURL}/api/artist-suggestions/?format=json&query=${query}`)
          .then((response) => response.json())
          .then((data) => setOptions(data))
          .catch((error) =>
            console.error("Error fetching artist data:", error)
          );
      }, 400),
    []
  );

  React.useEffect(() => {
    if (reset) {
      setInputValue("");
      setSelectedArtists([]);
    }
  }, [reset]);

  React.useEffect(() => {
    setSelectedArtists(artistSearchTerm.map((name) => ({ id: 0, name })));
  }, [artistSearchTerm]);

  React.useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
    } else {
      fetchArtists(inputValue);
    }
  }, [inputValue, fetchArtists]);

  React.useEffect(() => {
    console.log("Selected Artists in ArtistSearchBar:", selectedArtists);
  }, [selectedArtists]);

  return (
    <Autocomplete
      multiple
      id="artist-search-input"
      options={options}
      getOptionLabel={(option: Artist) => option.name}
      filterSelectedOptions
      value={selectedArtists}
      onChange={(event, newValues) => {
        setSelectedArtists(newValues);
        onArtistSearchChange(newValues);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Søk etter artist"
          placeholder="Søk etter flere artister"
          sx={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 0, 0, 0.5)',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'rgba(255, 0, 0, 0.8)',
            },
          }}
        />
      )}
      sx={{
        width: "100%",
        maxWidth: "100%",
        margin: "0 auto",
        background: "white",
      }}
    />
  );
};

export default ArtistSearchBar;
