import * as React from "react";
import {
  SelectChangeEvent,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

interface YearPickerProps {
  onYearChange: (year: number | null) => void; // allows null for 'All Years'
  selectedYear: number | null; // allows null for 'All Years'
  currentYear: number;
}

const YearPicker: React.FC<YearPickerProps> = ({
  onYearChange,
  selectedYear,
  currentYear,
}) => {
  const [year, setYear] = React.useState<number | null>(selectedYear === -1 ? null : selectedYear);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    if (value === "All" || value === "-1") {
      setYear(null);
      onYearChange(null);
    } else {
      const numValue = parseInt(value as string);
      setYear(numValue);
      onYearChange(numValue);
    }
  };

  React.useEffect(() => {
    setYear(selectedYear === -1 ? null : selectedYear);
  }, [selectedYear]);

  // Explicitly type the years array
  const years: number[] = [];
  for (let i = currentYear; i >= 2009; i--) {
    years.push(i);
  }

  return (
    <FormControl 
      variant="outlined" 
      fullWidth 
      sx={{
        bgcolor: 'white',
        borderRadius: '8px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 0, 0, 0.5)',
          },
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'rgba(255, 0, 0, 0.8)',
        },
      }}
    >
      <InputLabel id="year-label">År</InputLabel>
      <Select
        labelId="year-label"
        label="År"
        value={year === null ? "All" : year.toString()}
        onChange={handleChange}
        sx={{
          backgroundColor: "white", // Optional: keep if you want white background
          width: "100%",
          textAlign: "center",
          '& .MuiSelect-select': {
            paddingRight: '8px', // Correct the padding value if needed
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 0, 0, 0.5)', // Same border color when focused
          },
        }}
      >
        <MenuItem value="All" sx={{ justifyContent: "center" }}>
          Alle
        </MenuItem>
        {years.map((year: number) => (
          <MenuItem
            key={year}
            value={year.toString()}
            sx={{ justifyContent: "center" }}
          >
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

  );
};

export default YearPicker;
